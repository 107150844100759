<template>
  <small :title="'Hochgeladen am ' + $filters.datetime(video.created)" class="text-muted">{{ $filters.date(video.created) }} · {{ video.lengthMin }} min</small>
</template>

<script>
export default {
  name: "VideoDetails",

  props: ["video"]
}
</script>

<style scoped>

</style>