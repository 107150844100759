<template>
  <div class="mt-4 pm-container">

    <div class="card p-4">
      <div class="row">
        <div class="col">
          <section class="jumbotron text-center">
            <div class="container-fluid">
              <h2 class="jumbotron-heading">Mediathek</h2>
              <p class="lead text-muted">Hier finden sich Produktvideos und hilfreiche Tipps 😊</p>
            </div>
          </section>
        </div>
      </div>

    <hr>
    <div class="row">
      <div class="col">
        <ul class="nav nav-pills mb-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Allgemein</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Chris Farell</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Lupin</a>
          </li>
          <router-link v-if="isAdmin" class="btn btn-success" to="/mediathek/neu">Neues Video einstellen
          </router-link>
        </ul>
      </div>
    </div>

    <hr>

    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">🔎</span>
      <input v-model.lazy="search" v-debounce="400" class="form-control" type="text"
             placeholder="Nach Video suchen">
    </div>
    </div>

    <transition name="fade">
      <div v-if="!loading" class="p-2 mb-5">
        <div v-for="v in videos" v-bind:key="v.token" class="row">
          <div :id="v.token" class="card mb-3">
            <div class="row">
              <div class="col-md-4">
                <img @click="imageClick(v)" :src=thumbnailLinkPreview(v) class="thumbnail img-fluid rounded-start mt-2">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">🌞 {{ v.title }}</h5>
                  <p class="card-text">{{ v.description }}</p>
                  <p class="card-text">
                    <VideoDetails :video="v"></VideoDetails>
                  </p>
                  <div class="btn-group">
                    <router-link :to="'/mediathek/' + v.token" class="btn btn-sm btn-primary">Jetzt ansehen
                    </router-link>
                    <router-link v-if="isAdmin" :to="'/mediathek/' + v.token + '/bearbeiten'"
                                 class="btn btn-sm btn-secondary">
                      Bearbeiten
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VideoDetails from "../../components/VideoDetails";

export default {
  name: "Videos",
  components: {VideoDetails},

  data() {
    return {
      search: ""
    }
  },

  beforeMount() {
    this.doSearch();
  },

  watch: {
    search() {
      this.doSearch()
    },
  },

  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        el && el.scrollIntoView();
      }
    })
  },

  methods: {
    imageClick(video) {
      this.$router.push('/mediathek/' + video.token);
    },

    doSearch() {
      this.$store.dispatch('videos/list', {search: this.search})
    },

    thumbnailLinkPreview(video) {
      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + video.thumbnailBlobId);
    },

  },

  computed: {

    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },

    videos() {
      return this.$store.state.videos.videos
    },

    loading() {
      return this.$store.state.videos.loading;
    }
  }
}
</script>

<style scoped>
img {
  object-fit: fill;
}

.thumbnail:hover {
  cursor: pointer;
}
</style>